import React, { useEffect, useState, useContext } from 'react';
import { Game } from './Game';
import { Character } from './Character';
import { Welcome } from './Welcome';
import { Scene } from './Scene/Scene';
import FadeIn from 'react-fade-in';
import { Sex } from './SexEnums';
import { CharacterName } from './CharacterName';
import { StopGame } from './StopGame';
import {SCORM} from 'pipwerks-scorm-api-wrapper';
import ReactPlayer from 'react-player'
import { SizeContext } from '../context';
import './Coordinator.css';
import { ImageButton } from './Components/ImageButton';

export const Coordinator: React.FC = ({}) => {
    const [startGame, setStartGame] = useState(false);
    const [gameType, setGameType] = useState('');
    const [gameNumber, setGameNumber] = useState(1);
    const [characterName, setSelectedCharacterName] = useState('');
    const [characterImage, setSelectedCharacterImage] = useState('');
    const [characterSex, setCharacterSex] = useState(Sex.male);
    const [characterVideo, setSelectedCharacterVideo] = useState('');

    const [autoPlayScene, setAutoPlayScene] = useState(true);
    const [promtStopGame, setPromptStopGame] = useState(false);

    const [showMain, setShowMain] = useState(false);
    const [showMainOptions, setShowMainOptions] = useState(false);

    const [titleTop, setTitleTop] = useState('100px');
    const [titleWidth, setTitleWidth] = useState('100px');
    const [titleHeight, setTitleHeight] = useState('100px');

    const context = useContext(SizeContext);

    useEffect(()=> {
        var windowHeight = context.height;
        var _top = 381 * windowHeight / 1040;
        setTitleTop(_top + 'px');
       
        var _width = 600 * windowHeight / 1040;
        setTitleWidth(_width + 'px');
        var _height = 40 * windowHeight / 1040;
        setTitleHeight(_height + 'px');

        if  (localStorage.getItem("started") === "true")
            setShowMainOptions(true);
        else
            setShowMainOptions(false);
      },[])

    const stopGame = () => {
        setSelectedCharacterVideo('');
        setSelectedCharacterImage('');
        setSelectedCharacterName('');
        setStartGame(false);
        setGameType('')
        setPromptStopGame(false);
        setAutoPlayScene(true);

        localStorage.clear();

        SCORM.set('cmi.core.lesson_status', 'failed');
        SCORM.set('cmi.core.exit', 'suspend');
        SCORM.save();
        SCORM.quit();
    }

    const onClick = () => {
        setShowMain(true)
        setStartGame(localStorage.getItem("started") === "true");
        setGameType("main");
        setSelectedCharacterImage(localStorage.getItem("image") ?? "");
        setCharacterSex(localStorage.getItem("sex") === Sex.male ? Sex.male : Sex.female);
        setSelectedCharacterVideo(localStorage.getItem("video") ?? "");
        setSelectedCharacterName(localStorage.getItem("name") ?? "");
    }

    const onRestart = () => {
        localStorage.clear();
        onClick();
    }

    return (
        // We need to display the muted video all times. If I do not, then the other videos won't start
        <> {!showMain && !showMainOptions &&
                <button className='ImageButton' onClick={onClick}>
                    <ReactPlayer 
                        playing={true}
                        url={"videos/first_screen.mp4"}
                        width='100%'
                        height='100%'
                        loop={false}
                        muted={true}
                    />
                </button>
            }
            {!showMain && showMainOptions &&
                <div className="RestartVideo" style={{top: titleTop}}>
                    <ReactPlayer 
                        playing={true}
                        url={"videos/choice_to_restart.mp4"}
                        width='100%'
                        height='100%'
                        loop={false}
                        muted={true}
                    />

                    <div className='GameTitleButtons'>
                        <ImageButton
                            image='images/buttons/continue.png'
                            onClick={onClick}
                        />
                         <ImageButton
                            image='images/buttons/restart.png'
                            onClick={onRestart}
                        />
                    </div>
                </div>
            }
            {showMain &&  !startGame &&
                <Welcome onStart={()=> {
                    localStorage.setItem("started", "true");
                     setStartGame(true); setGameType("main");}}/>
            }
            {showMain && startGame && gameType == '' &&
                <Game 
                    onBack={()=> setStartGame(false)}
                    onExit={()=> setStartGame(false)}
                    onNext={(type:string, game: number)=> {
                        localStorage.setItem("type", type);
                        setGameType(type); 

                        setGameNumber(game);
                    }}
                />
            }
            {showMain &&  startGame && gameType !== '' && characterImage == '' &&
                <Character
                    onBack={()=> {setGameType('main'); setStartGame(false)}}
                    onExit={()=> setGameType('main')}
                    onNext={(image: string, sex: Sex, video: string) => {
                        localStorage.setItem("image", image);
                        setSelectedCharacterImage(image); 
                        
                        localStorage.setItem("sex", sex);
                        setCharacterSex(sex); 

                        localStorage.setItem("video", video);
                        setSelectedCharacterVideo(video)
                    }}
                />
            }
            {showMain &&  startGame && gameType !== '' && characterVideo != '' && characterImage != '' && characterName == '' && 
                <CharacterName
                    video={characterVideo}
                    onBack={()=> {setSelectedCharacterImage(''); setSelectedCharacterVideo('');}}
                    onExit={()=> setGameType('main')}
                    onNext={(name: string) => {
                        localStorage.setItem("name", name);
                        setSelectedCharacterName(name)}
                    }
                />
            }
            {showMain && startGame && gameType !== '' && characterImage != '' && characterName != '' &&
                <FadeIn>
                    <Scene
                        characterName={characterName}
                        characterImage={characterImage}
                        characterSex={characterSex}
                        characterVideo={characterVideo}
                        gameType={gameType}
                        gameNumber={gameNumber}
                        autoPlay={autoPlayScene}
                        onBack={()=> {setPromptStopGame(true); setAutoPlayScene(false)} }
                    />
                </FadeIn>
            }

            {showMain && promtStopGame &&
                <StopGame
                    onExit={stopGame}
                    onCancel={()=> { setPromptStopGame(false); setAutoPlayScene(true)}}
                />
            }
        </>
    )
}