import React, { useEffect, useState, useContext } from 'react';
import { ExitButton } from './Components/ExitButton';
import { BackButton } from './Components/BackButton';
import ReactPlayer from 'react-player'
import { ImageButton } from './Components/ImageButton';
import { SizeContext } from '../context';
import './CharacterName.css';
import { readBuilderProgram } from 'typescript';

interface CharacterNameProps{
    video: string;
    onExit: () => void;
    onBack: () => void;
    onNext: (name: string) => void;
}

export const CharacterName: React.FC<CharacterNameProps> = ({video, onExit, onBack, onNext}: CharacterNameProps) => {
    const [topTitle, setTopTitle] = useState('100px');
    const [middleOpacity, setMiddleOpacity] = useState(0.3);
    const [photo, setPhoto] = useState('');
    const [value, setValue] = useState('');
    const [enableButton, setEnableButton] = useState(false);

    const context = useContext(SizeContext);

    useEffect(()=> {
        var windowHeight = context.height;
        var _top = 230 * windowHeight / 1040;
        setTopTitle(_top + 'px');

        if (video == "heroe1") {
            setPhoto("images/photo1.png");
        } else if (video == "heroe2") {
            setPhoto("images/photo2.png");
        } else if (video == "heroe3") {
            setPhoto("images/photo3.png");
        } else if (video == "heroe4") {
            setPhoto("images/photo4.png");
        }

        
      },[])

    const moveToNext = () => {
        var element = document.getElementById("charactername");
        if (element) {
            element.classList.add('animate');
        }
        setTimeout(function(){
            onNext(value)
        }, 300);
    }

    const handleChange = (event: any) => {
        const result = event.target.value.toUpperCase().trim();
    
        setEnableButton(result.length >=3 ? true : false);
        setMiddleOpacity(result.length < 3 ? 0.3 : 1)
        setValue(result);
      };

    return (
        <div className="MainBG" id="charactername">
            <BackButton onClick={onBack}/>

            <div className='CharacterNameTitle' style={{top: topTitle}}>
                <ReactPlayer 
                    playing={true}
                    url={"videos/pick_name.mp4"}
                    width='100%'
                    height='95px'
                    loop={false}
                    muted={false}
                />
                <div className='CharacterPhotoContainer' style={{top: 30}}>
                    <img src={photo} alt='' style={{ width: "100%", height: "auto", margin: 0, padding: 0 }}/> 
                </div>
                <div className='CharacterEnterNameContainer' style={{top: -180}}>
                <input type="text" placeholder="" maxLength={12} minLength={3} value={value} onChange={handleChange} />
                <ReactPlayer 
                    playing={true}
                    url={"videos/explain.mp4"}
                    width='100%'
                    height='40px'
                    loop={false}
                    muted={false}
                />
                </div>
            </div>
            <div className={enableButton ? "PlayGame" : "PlayGameDisabled"} style={{top: 150, opacity: middleOpacity}}>
                    <ImageButton
                        image='images/start.png'
                        onClick={enableButton ? moveToNext : undefined}
                    />
                 </div>
        </div>
    )
}