import React from 'react';

import './SceneBottom.css';

interface SceneBottomProps {
    name: string;
    answer: string;
    image?: string;
}

export const SceneBottom: React.FC<SceneBottomProps> = ({name, image, answer}:SceneBottomProps) => {

    var color = "#E6DE73";
    if (image === "images/manos.png") {
        color = "#F0BE83"
    } else if (image === "images/maria.png") {
        color = "#B2E124"
    } else if (image === "images/ntinos.png") {
        color = "#FFEE85"
    } else if (image === "images/domna.png") {
        color = "#E0BEE9"
    } else if (image === "images/elena.png") {
        color = "#E0BEE9"
    } else if (image === "images/christina.png") {
        color = "#FFFFFF"
    } else if (image === "images/george.png") {
        color = "#D2E0ED"
    } else if (image === "images/thodoris.png") {
        color = "#F0BE83"
    } else if (image === "images/dieuthyntis.png") {
        color = "#D2E0ED"
    } else if (image === "images/elvira.png") {
        color = "#D2E0ED"
    } else if (image === "images/marina.png") {
        color = "#B2E124"
    } 
    
    return (
        <div className='BottomBarContainer'>
            <div className='BottomBar'>
                <div className='BottomBarCharacterName' style={{color: color}}>
                    {name}
                </div>
                <div className='BottomBarImage'>
                    {image && 
                        <img src={image} alt='' style={{ width: "100%", height: "auto", margin: 0, padding: 0 }}/> 
                    }
                </div>
                {answer &&
                    <div className='BottomBarReponse' style={{color: color}}>
                        {answer}
                    </div>
                }
            </div>
        </div>
    )
}